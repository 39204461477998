import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { EmployeeContext } from 'AppContexts';
import moment from 'moment';
import { isMobileDevice, removeStaticsPicker } from 'utils';

const styles = (theme) => ({
	root: {
		margin: 0,
		width: '100%',
		padding: theme.spacing(3)
	},
	inputs: {
		'justify-content': 'center',
		margin: '14px 0 10px 0',
		padding: '0 0 25px 0'
	},
	inputs100w: {
		'& ,MuiInput-formControl': {
			width: '100%'
		}
	},
	dateRange: {
		'justify-content': 'center',
		margin: '10px 0 10px 0',
		padding: '0 0 25px 0'
	},
	manually_days: {
		width: '100%',
		display: 'flex',
		'justify-content': 'space-between',
		margin: '28px 0 0px 0'
	},
	content: {
		'max-width': '700px',
		display: 'inline-grid'
	},
	help: {
		'text-align': 'center',
		'font-size': '1em',
		'font-weight': 'bold',
		'margin-bottom': 0,
		'& .highlight': {
			color: theme.palette.primary.light
		}
	},
	'@global': {
		'.react-daterange-picker__wrapper': {
			border: 'none',
			'border-bottom': '1px solid rgba(0, 0, 0, 0.54)'
		},
		'.react-daterange-picker__range-divider': {
			'line-height': '3',
			width: '22px',
			'text-align': 'center'
		}
	},
	dialog: {
		height: '93vh',
		width: '100%'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	}
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1)
	}
}))(MuiDialogActions);

class DaysOffCompensationForm extends React.Component {
	static contextType = EmployeeContext;

	state = {
		type: 'compensation',
		amount: 0,
		employee: this.context,
		description: '',
		date: [
			{
				startDate: new Date(),
				endDate: new Date(),
				key: 'selection'
			}
		]
	};

	onEnter = () => {
		removeStaticsPicker();
		this.setState({
			description: '',
			amount: 0,
			date: [
				{
					startDate: new Date(),
					endDate: new Date(),
					key: 'selection'
				}
			]
		});
	};

	handleSubmit = () => {
		const data = this.state;
		const { startDate, endDate } = data.date[0];

		if (!moment(startDate).isSame(new Date(), 'days', true)) {
			data.start_date = startDate;
			data.end_date = endDate;
		}

		this.props.onSubmit(data);
	};

	onChangeDescription = (e) => this.setState({ [e.target.name]: e.target.value });

	onChangeDate = (date) => this.setState({ date: [date.selection] });

	render() {
		const { open, onClose, classes } = this.props;
		const { date, description } = this.state;

		return (
			<Dialog
				onClose={onClose}
				aria-labelledby="dialog-title"
				open={open}
				maxWidth="xl"
				className={classes.root}
				TransitionProps={{
					onEnter: this.onEnter
				}}
			>
				<DialogTitle id="dialog-title" onClose={onClose}>
					Request to swap your days off for later
				</DialogTitle>

				<DialogContent dividers>
					<div className={classes.content}>
						<p className={classes.help}>
							This form allows you to swap your days off to work and save it/them for the
							future. Choose a day or timespan in which you want to work even if it's a day
							off. This will generate a day off swap request for you, which you'll be able to
							use later as a <span className="highlight">days off compensation</span>!
						</p>

						<DateRangePicker
							rangeColors={['#15A6D9']}
							className={classes.inputs}
							startDatePlaceholder="From"
							endDatePlaceholder="To"
							showSelectionPreview={true}
							moveRangeOnFirstSelection={false}
							months={2}
							direction={isMobileDevice() ? 'vertical' : 'horizontal'}
							minDate={new Date(new Date().getTime() + 24 * 60 * 60 * 1000)}
							maxDate={undefined}
							ranges={date}
							onChange={this.onChangeDate}
							staticRanges={[]}
							inputRanges={[]}
						/>

						<TextField
							className={classes.inputs}
							onChange={this.onChangeDescription}
							name="description"
							key="description"
							value={description}
							inputProps={{ autoComplete: 'off' }}
							label="Description"
							multiline
							rows="6"
							fullWidth
							placeholder="Please input here anything you consider important for processing your request appropriately, e.g.: 'I will work on Independence Day and save the day off for later'"
						/>

						<p className={classes.help}>
							Once created, you must wait for{' '}
							<span className="highlight">your PM to check and confirm it</span> in order to
							use them.
						</p>
					</div>
				</DialogContent>

				<DialogActions>
					<Button autoFocus onClick={this.handleSubmit} color="primary">
						Save
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withStyles(styles)(DaysOffCompensationForm);
