import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { removeUnderscore } from 'utils';
import moment from 'moment';
import { readableFormatDate } from 'utils';

const styles = (theme) => ({
	root: {
		margin: 0
	},
	days: {
		margin: '3px'
	},
	small: {
		width: theme.spacing(3),
		height: theme.spacing(3),
		'font-size': '1em',
		background: 'white',
		color: theme.palette.primary.main,
		border: `1px solid ${theme.palette.primary.main}`
	}
});

const EmployeeDays = (props) => {
	const { classes, dayOff } = props;
	const {
		type,
		amount,
		event_binded,
		includes_weekend,
		min_consecutive,
		max_consecutive,
		active,
		created_at,
	} = dayOff;

	const includesWeekend = includes_weekend ? '{literally, no matter weekends}' : '';
	const minConsecutive = min_consecutive
		? `${min_consecutive} Minimal consecutive days ${includesWeekend}, `
		: '';
	const maxConsecutive = max_consecutive
		? `${max_consecutive} Maximum consecutive days, `
		: '';
	const eventBind = event_binded
		? 'Could be requested as many time the event occurs'
		: '';
	const title = minConsecutive + maxConsecutive + eventBind;
	let inactiveDescription = '';

	if (!active) {
		inactiveDescription = " (Your PM didn't approve it yet)";
	}

	let compensationDayExpirationDate = '';
	if (type === 'compensation' && active) {
		compensationDayExpirationDate = ` (expires on ${readableFormatDate(moment.utc(created_at).add(1, 'year'))})`;
	}

	return (
		<div className={classes.root}>
			<Chip
				className={classes.days}
				color="primary"
				icon={<Avatar className={classes.small}>{amount}</Avatar>}
				label={removeUnderscore(type.toUpperCase()) + inactiveDescription + compensationDayExpirationDate}
				variant="outlined"
				title={title}
				disabled={!active}
			/>
		</div>
	);
};

export default withStyles(styles)(EmployeeDays);
