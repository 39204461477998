import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Selector from 'components/ui/Selector';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { EmployeeContext } from 'AppContexts';
import moment from 'moment';
import { isMobileDevice, removeStaticsPicker, getUTCDate } from 'utils';
import Alert from '@material-ui/lab/Alert';
import NoManagerAlert from './NoManagerAlert';

const styles = (theme) => ({
	root: {
		margin: 0,
		width: '100%',
		padding: theme.spacing(3)
	},
	inputs: {
		'justify-content': 'center',
		margin: '14px 0 10px 0',
		padding: '0 0 25px 0'
	},
	inputs100w: {
		'& ,MuiInput-formControl': {
			width: '100%'
		}
	},
	dateRange: {
		'justify-content': 'center',
		margin: '10px 0 10px 0',
		padding: '0 0 25px 0'
	},
	manually_days: {
		width: '100%',
		display: 'flex',
		'justify-content': 'space-between',
		margin: '28px 0 0px 0'
	},
	content: {
		'max-width': '700px',
		display: 'inline-grid'
	},
	help: {
		'text-align': 'center',
		'font-size': '1em',
		'font-weight': 'bold',
		'margin-bottom': 0,
		'& .highlight': {
			color: theme.palette.primary.light
		}
	},
	'@global': {
		'.react-daterange-picker__wrapper': {
			border: 'none',
			'border-bottom': '1px solid rgba(0, 0, 0, 0.54)'
		},
		'.react-daterange-picker__range-divider': {
			'line-height': '3',
			width: '22px',
			'text-align': 'center'
		}
	},
	dialog: {
		height: '93vh',
		width: '100%'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500]
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	alert: {
		marginBottom: '25px'
	}
});

const DialogTitle = withStyles(styles)((props) => {
	const { children, classes, onClose, ...other } = props;
	return (
		<MuiDialogTitle disableTypography {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1)
	}
}))(MuiDialogActions);

const VACATION_MINIMUM_ANTICIPATION_DAYS = 30;

class DaysClaimForm extends React.Component {
	static contextType = EmployeeContext;

	state = {
		type: 'vacation',
		state: 'on_review',
		amount: 0,
		employee: this.context._id,
		holidays_inside: 0,
		weekends_inside: 0,
		vacation_postponed_days: 0,
		vacation_current_days: 0,
		vacation_inadvance_days: 0,
		request_description: '',
		response_description: '',
		date: [
			{
				startDate: new Date(),
				endDate: new Date(),
				key: 'selection'
			}
		],
		showAnticipationWarning: false
	};

	onEnter = () => {
		if (this.props.claim) {
			const {
				holidays_inside,
				weekends_inside,
				employee,
				end_date,
				start_date,
				response_description,
				request_description,
				vacation_inadvance_days,
				vacation_current_days,
				vacation_postponed_days,
				state,
				amount,
				type
			} = this.props.claim;

			this.setState(
				{
					type,
					state,
					amount,
					employee: employee._id,
					holidays_inside: holidays_inside ? holidays_inside : '',
					weekends_inside: weekends_inside ? weekends_inside : '',
					vacation_postponed_days,
					vacation_current_days,
					vacation_inadvance_days,
					request_description,
					response_description,
					date: [
						{
							startDate: getUTCDate(start_date),
							endDate: getUTCDate(end_date),
							key: 'selection'
						}
					]
				},
				() => {
					this.checkVacationAnticipation();
				}
			);
		}

		removeStaticsPicker();
	};

	handleSubmit = () => {
		const data = { ...this.state };
		const { claim } = this.props;
		const { startDate, endDate } = data.date[0];
		if (!moment(startDate).isBefore(new Date(), 'days', true)) {
			data.start_date = startDate;
			data.end_date = endDate;
		}

		if (claim) {
			data.id = claim._id;
		}

		delete data.showAnticipationWarning;
		delete data.date;
		this.props.onSubmit(data);
	};

	onChangeDescription = (e) => this.setState({ [e.target.name]: e.target.value });

	onChangeDays = (e) => {
		const { loggedEmployee = this.context } = this.props.claim;
		const key = e.target.name;
		const value = e.target.value;

		if (value >= 0) {
			this.setState({ [key]: value });
		}
	};

	onChangeDate = (date) => {
		this.setState({ date: [date.selection] }, () => {
			this.checkVacationAnticipation();
		});
	};

	onChangeType = (type) => {
		this.setState({ type }, () => {
			this.checkVacationAnticipation();
		});
	};

	onChangeState = (state) => this.setState({ state });

	checkVacationAnticipation = () => {
		if (this.state.type === 'vacation') {
			const startDate = this.state.date[0].startDate;
			const anticipationDays = moment(startDate).diff(moment(), 'days');

			const showAnticipationWarning =
				anticipationDays < VACATION_MINIMUM_ANTICIPATION_DAYS;

			this.setState({
				showAnticipationWarning
			});
		} else {
			this.setState({
				showAnticipationWarning: false
			});
		}
	};

	render() {
		const { open, onClose, classes, claim, getTypes } = this.props;
		const {
			showAnticipationWarning,
			weekends_inside,
			holidays_inside,
			date,
			request_description,
			type,
			state,
			response_description,
			vacation_postponed_days,
			vacation_current_days,
			vacation_inadvance_days
		} = this.state;
		const isManagerReview =
			claim && claim.employee._id !== this.context._id && this.context.is_manager;
		const isManager = this.context.is_manager;
		const hasManager = this.context.manager_id;
		const specialEmployee = isManager && !hasManager;

		return (
			<Dialog
				onClose={onClose}
				aria-labelledby="dialog-title"
				open={open}
				maxWidth="xl"
				className={classes.root}
				TransitionProps={{
					onEnter: this.onEnter
				}}
			>
				<DialogTitle id="dialog-title" onClose={onClose}>
					Request your days
				</DialogTitle>

				<DialogContent dividers>
					<div className={classes.content}>
						<p className={classes.help}>
							Choose a timespan in which you want to take days-off. You can pick as many
							days as you've accumulated (
							<a
								rel="noopener noreferrer"
								href="https://docs.google.com/document/d/1_OxLkzS7OpjOmh6HDu4sHsVUqO2UctaTok6VC1HH9y0/edit"
								target="_blank"
							>
								learn more here
							</a>
							). Weekends don't count as days off, so don't mind them within your selected
							timespan.
						</p>

						<DateRangePicker
							rangeColors={['#15A6D9']}
							className={classes.inputs}
							startDatePlaceholder="From"
							endDatePlaceholder="To"
							showSelectionPreview={true}
							moveRangeOnFirstSelection={false}
							months={2}
							direction={isMobileDevice() ? 'vertical' : 'horizontal'}
							minDate={new Date(new Date().getTime() + 24 * 60 * 60 * 1000)}
							maxDate={
								isManagerReview
									? new Date(new Date().getTime() + 1 * 60 * 60 * 1000)
									: undefined
							}
							ranges={date}
							onChange={this.onChangeDate}
							staticRanges={[]}
							inputRanges={[]}
						/>

						{showAnticipationWarning && (
							<div className={classes.alert}>
								<Alert severity="warning">
									Warning: employees should send a request for their{' '}
									<span className="highlight"> vacation </span> days at least 30 days before
									the start date. Ignoring this could compromise the approval of the claim.{' '}
									<a
										rel="noopener noreferrer"
										href="https://docs.google.com/document/d/1_OxLkzS7OpjOmh6HDu4sHsVUqO2UctaTok6VC1HH9y0/edit"
										target="_blank"
									>
										(learn more here)
									</a>
								</Alert>
							</div>
						)}

						{specialEmployee && <NoManagerAlert />}

						<div>
							<Selector
								key="type_selector"
								name="type"
								value={type}
								disabled={isManagerReview}
								onChange={this.onChangeType}
								options={getTypes()}
							/>
							<p className={classes.help}>
								Choose the request type; it's important to be as accurate as possible.{' '}
								<span className="highlight">
									Bear in mind that vacation days have accountancy implications
								</span>
								. Remember that Exam, Vacation and Compensation days off get discounted from
								your accumulated.
							</p>
						</div>

						<TextField
							className={classes.inputs}
							onChange={this.onChangeDescription}
							name="request_description"
							key="request_description"
							value={request_description}
							inputProps={{ autoComplete: 'off' }}
							label="Request description"
							multiline
							rows="6"
							fullWidth
							disabled={isManagerReview}
							placeholder="Please input here anything you consider important for processing your request appropriately, e.g.: national holidays or non-workable days within your chosen timespan, among other situations. Be as detailed as you can."
						/>

						{!isManagerReview && (
							<p className={classes.help}>
								If your request gets approved, but you end up not taking those days off,{' '}
								<span className="highlight">
									please remember to delete it in order to save those days for later
								</span>
							</p>
						)}

						<div>
							{isManagerReview && (
								<div>
									<Selector
										key="state_selector"
										name="state"
										value={state}
										onChange={this.onChangeState}
										options={{
											approved: 'Approved',
											declined: 'Declined'
										}}
									/>
									<p className={classes.help}>
										Manager: please review the request and decide wisely whether to approve or
										decline it.
									</p>

									{type === 'vacation' && (
										<>
											<div className={classes.manually_days}>
												<TextField
													label="Postponed days to be used"
													type="number"
													name="vacation_postponed_days"
													value={vacation_postponed_days}
													onChange={this.onChangeDays}
												/>
												<TextField
													label="Current days to be used"
													type="number"
													name="vacation_current_days"
													value={vacation_current_days}
													onChange={this.onChangeDays}
												/>
												<TextField
													label="Future days to be used"
													type="number"
													name="vacation_inadvance_days"
													value={vacation_inadvance_days}
													onChange={this.onChangeDays}
												/>
											</div>
											<p className={classes.help}>
												Visualize and change (if needed) the amount of days that{' '}
												<span className="highlight">this request will discount them</span> from
												employee's available days.
											</p>

											<div className={classes.manually_days}>
												<TextField
													label="Holidays within requested timespan"
													type="number"
													name="holidays_inside"
													className={classes.inputs100w}
													value={holidays_inside}
													onChange={this.onChangeDays}
												/>
											</div>
											<p className={classes.help}>
												View and edit them (if needed).{' '}
												<span className="highlight">
													This is not automatic, so remember you must discount any existing
													holidays from the requested days in the chosen timespan, on the fields
													located right above.
												</span>
											</p>

											<div className={classes.manually_days}>
												<TextField
													label="Weekend days within requested timespan"
													type="number"
													name="weekends_inside"
													value={weekends_inside}
													className={classes.inputs100w}
													onChange={this.onChangeDays}
												/>
											</div>
											<p className={classes.help}>
												You can also view and edit them. This won't imply any other actions.
											</p>
										</>
									)}

									<TextField
										className={classes.inputs}
										onChange={this.onChangeDescription}
										name="response_description"
										key="response_description"
										value={response_description}
										inputProps={{ autoComplete: 'off' }}
										label="Response description"
										multiline
										rows="6"
										fullWidth
										placeholder="Manager, please input here the reasons (if any) why the request was approved or declined, be as detailed as you can."
									/>
								</div>
							)}
						</div>
					</div>
				</DialogContent>

				<DialogActions>
					<Button autoFocus onClick={this.handleSubmit} color="primary">
						Save
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withStyles(styles)(DaysClaimForm);
